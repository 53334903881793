<template>
  <b-card no-body class="border mt-1" cols="12" md="12">
    <b-card-header class="p-1">
      <b-card-title class="font-medium-2">
        <feather-icon icon="LockIcon" size="18" />
        <span class="align-middle ml-50">COMMISSION EMPLOYEE </span>
        <b-badge variant="warning" v-if="assignedTotalTips">
          to assign tips = $ {{ assignedTotalTips }}
        </b-badge>
      </b-card-title>
    </b-card-header>

    <b-table
      striped
      responsive
      class="mb-200"
      :fields="fields"
      :items="permissionsData"
    >
      <template #cell(id)="data">
        {{ data.value }}
      </template>
      <template #cell(name)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-input
          :key="data.index"
          type="number"
          v-on:keyup="commisssionItems(data.index, data)"
          v-model="data.item.commission"
        />
      </template>
      <template #cell(total)="data">
        <b-form-input
          :key="data.total"
          type="number"
          v-on:keyup="commisssionItems(data.total, data)"
          v-model="data.item.total"
        />
      </template>

      <template #cell(progress)="data">
        <b-progress :value="data.item.progress" max="100" />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BProgress,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BBadge } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BProgress,
    BBadge,
  },
  props: {
    employeeData: {
      type: Array,
      required: true,
    },
    tips: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const toast = useToast();

    const { employeeData, tips } = props;
    const permissionsData = ref([]);

    const dataEmployee = [];
    let assignedTotalTips = ref(0);

    const setObj = new Set();

    employeeData.map((data, index) => {
      if (!setObj.has(data.employeeId)) {
        if (data.typeTip == 'Services') {
          setObj.add(data.employeeId, data);
          dataEmployee.push({
            id: data.employeeId,
            employeeId: data.employeeId,
            name: data.employee,
            commission: 0,
            total: 0,
            progress: 0,
          });
        }
      }
    });

    permissionsData.value = dataEmployee;

    const dataItemsEmployeeCommission = (data) => {
      context.emit("dataTipsPersonalized", data);
    };

    const totalCommision = () => {
      const arrayCommission = permissionsData.value.map((data) => {
        return data.commission;
      });

      const totalCommissions = arrayCommission.reduce((previous, current) => {
        return parseInt(previous) + parseInt(current);
      }, 0);

      return totalCommissions > 100 ? false : true;
    };

    return {
      permissionsData,
      fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "name" },
        { key: "commission", label: "commission (%)" },
        { key: "total", label: "total usd" },
        { key: "progress", label: "progress" },
      ],
      commisssionItems: (index, data) => {
        const { item, field } = data;
        const total = totalCommision();
        let sumTips = 0;
        let sumCommission = 0;
        permissionsData.value.map((itemData, dataItem) => {
          sumTips = parseFloat(sumTips) + parseFloat(itemData.total);
          sumCommission =
            parseInt(sumCommission) + parseInt(itemData.commission);
        });

        assignedTotalTips.value = parseInt(tips) - parseInt(sumTips);

        if (sumTips > tips) {
          item.commission = 0;
          item.progress = 0;
          item.total = 0;
          assignedTotalTips.value = 0;
          toast({
            component: ToastificationContent,
            props: {
              title: `Error the commission total should not be greater than $ ${tips}`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        if (sumTips > 100) {
          item.commission = 0;
          item.progress = 0;
          item.total = 0;
          assignedTotalTips.value = 0;
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Error the commission percentage should not be greater than 100 %",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        if (total) {
          if (item.commission <= 100) {
            if (field.key === "commission") {
              item.progress = item.commission;
              item.total = (item.commission * tips) / 100;
              dataItemsEmployeeCommission(data);
            }
            if (field.key === "total") {
              if (item.total > tips) {
                item.commission = 0;
                item.progress = 0;
                item.total = 0;
                assignedTotalTips.value = 0;
                toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error the commission total should not be greater than $ ${tips}`,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              } else {
                item.commission = ((item.total * 100) / tips).toFixed(0);
                item.progress = item.commission;
                dataItemsEmployeeCommission(data);
              }
            }
          } else {
            item.commission = 0;
            item.progress = 0;
            item.total = 0;
            assignedTotalTips.value = 0;
            toast({
              component: ToastificationContent,
              props: {
                title:
                  "Error the commission percentage should not be greater than 100 %",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        } else {
          item.commission = 0;
          item.progress = 0;
          item.total = 0;
          assignedTotalTips.value = 0;
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Error the sum of the commission percentage must not be greater than 100",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        assignedTotalTips.value = 0;
        sumTips = 0;
        permissionsData.value.map((itemData, dataItem) => {
          sumTips = parseFloat(sumTips) + parseFloat(itemData.total);
        });

        assignedTotalTips.value = parseFloat(tips) - parseFloat(sumTips);
      },
      assignedTotalTips,
    };
  },
};
</script>

<style lang="scss"></style>
