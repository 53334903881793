<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <validation-observer ref="simpleRules">
          <b-form>
            <b-card no-body class="invoice-preview-card">
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="
                    d-flex
                    justify-content-between
                    flex-md-row flex-column
                    invoice-spacing
                    mt-100
                  "
                >
                  <!-- Header: Left Content -->
                  <div style="width: 500px">
                    <div class="logo-wrapper">
                      <h3 class="text-primary invoice-logo">Mela Beauty</h3>
                    </div>
                    <b-col v-if="invoiceData.user.dataUser.rolId === 3">
                      <h6 class="mb-2">Office</h6>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Invoice To"
                      >
                        <!-- Select Client -->
                        <v-select
                          v-model="invoiceData.office"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="officesAdmin"
                          label="name"
                          input-id="invoice-data-client"
                          :clearable="false"
                          :required="!invoiceData.office"
                          @input="
                            (val) => filterAdminOffice(invoiceData.office.id)
                          "
                        >
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <!-- Selected Client -->
                      <div v-if="invoiceData.office" class="mt-1">
                        <b-card-text class="mb-25">
                          {{ invoiceData.office.name }}
                        </b-card-text>
                        <b-card-text class="mb-25">
                          {{ invoiceData.office.address }}
                        </b-card-text>
                      </div>
                    </b-col>
                    <div v-if="invoiceData.user.dataUser.offices_name">
                      <b-card-text class="mb-25">
                        {{ invoiceData.user.dataUser.offices_name }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ invoiceData.user.dataUser.cities_name }},
                        {{ invoiceData.user.dataUser.states_name }},
                        {{ invoiceData.user.dataUser.offices_adress }},
                        {{ invoiceData.user.dataUser.countries_name }}
                      </b-card-text>
                    </div>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-md-end
                        mb-1
                      "
                    >
                      <h4 class="invoice-title">Invoice</h4>
                      <b-input-group
                        class="
                          input-group-merge
                          invoice-edit-input-group
                          disabled
                        "
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceData.id"
                          disabled
                        />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="title">Date:</span>
                      <flat-pickr
                        v-model="invoiceData.issuedDate"
                        class="form-control invoice-edit-input"
                      />
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">
                    <h6 class="mb-2">Invoice To:</h6>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Invoice To"
                    >
                      <!-- Select Client -->
                      <v-select
                        v-model="invoiceData.client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="clients"
                        label="customer"
                        input-id="invoice-data-client"
                        :clearable="false"
                        :required="!invoiceData.client"
                      >
                        <template #list-header>
                          <li
                            v-b-toggle.sidebar-invoice-add-new-customer
                            class="
                              add-new-client-header
                              d-flex
                              align-items-center
                              my-50
                            "
                          >
                            <feather-icon icon="PlusIcon" size="16" />
                            <span class="align-middle ml-25"
                              >Add New Customer</span
                            >
                          </li>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <!-- Selected Client -->
                    <div v-if="invoiceData.client" class="mt-1">
                      <h6 class="mb-25">
                        {{ invoiceData.client.customer }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ invoiceData.client.company }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ invoiceData.client.adress }},
                        {{ invoiceData.client.country }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ invoiceData.client.phone_number }}
                      </b-card-text>
                      <b-card-text class="mb-0">
                        {{ invoiceData.client.mail }}
                      </b-card-text>
                    </div>
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                    <div>
                      <h6 class="mb-2">Payment Details:</h6>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">Total Due:</td>
                            <td>
                              <span class="font-weight-bold">
                                ${{ invoiceData.total.toFixed(2) }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
              <!-- Items Section  Comienza los calculos de productos y servicios -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <b-col cols="12">
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->

                          <b-col cols="12" lg="4">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="Type"
                            >
                              <label class="text-nowrap mr-50">Type</label>
                              {{ item.name }}
                              <v-select
                                v-model="item.type"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="categoryTypeOptions"
                                label="name"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Type"
                                @input="(val) => typeCategory(index, val)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>

                          <b-col cols="12" lg="7">
                            <label class="text-nowrap mr-50">Category</label>
                            <v-select
                              v-model="item.categoryType"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="categoryOptions"
                              label="name"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Category"
                              @input="(val) => CategoryItem(index, val)"
                            />
                          </b-col>

                          <b-col cols="12" lg="6">
                            <label class="text-nowrap mr-50">Item</label>

                            <v-select
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-model="item.name"
                              :options="relationItemsOptions"
                              label="name"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Select Item"
                              @input="(val) => updateItemForm(index, val)"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="text-nowrap mr-50">Cost</label>
                            <b-form-input
                              v-model="item.cost"
                              type="number"
                              class="mb-2"
                              v-on:keyup="totalData"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="text-nowrap mr-50">Qty</label>
                            <b-form-input
                              v-model="item.qty"
                              type="number"
                              class="mb-2"
                              v-on:keyup="totalData"
                            />
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="text-nowrap mr-50">Price</label>
                            <p class="mb-1">
                              ${{ (item.cost * item.qty).toFixed(2) }}
                            </p>
                          </b-col>
                          <b-col cols="12" lg="4">
                            <p class="mb-0">
                              <label
                                for="invoice-data-sales-person"
                                class="text-nowrap mr-50"
                              >
                                Sales Person:
                              </label>

                              <v-select
                                v-model="item.employee"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="employeeOptions"
                                label="employee"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Select Item"
                                aria-required="true"
                                :required="!item.employee"
                                @input="
                                  (val) => totalDataItemInputCost(index, val)
                                "
                              />
                            </p>
                          </b-col>
                          <b-col cols="12" lg="1">
                            <p class="mb-0">
                              <label
                                for="invoice-data-sales-person"
                                class="text-nowrap mr-50"
                                v-if="item.type_comission == 2"
                              >
                                Fixed:
                              </label>
                              <label
                                for="invoice-data-sales-person"
                                class="text-nowrap mr-50"
                                v-else
                              >
                                % Comm:
                              </label>
                            </p>

                            <p class="mb-1" v-if="item.type_comission == 2">
                              $ {{ item.percentage_comission }}
                            </p>
                            <p class="mb-1" v-else>
                              {{ item.percentage_comission }} %
                            </p>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <p class="mb-0">
                              <label
                                for="invoice-data-sales-person"
                                class="text-nowrap mr-50"
                              >
                                Comm Total $:
                              </label>
                            </p>

                            <p class="mb-1" v-if="item.type_comission == 2">
                              <b-form-input
                                :value="
                                  (
                                    item.qty * item.percentage_comission
                                  ).toFixed(2)
                                "
                                type="text"
                                class="mb-1"
                                @change="(val) => updateComission(index, val)"
                              />
                            </p>
                            <p class="mb-1" v-else>
                              <b-form-input
                                :value="
                                  (
                                    (item.cost *
                                      item.qty *
                                      item.percentage_comission) /
                                    100
                                  ).toFixed(2)
                                "
                                @change="(val) => updateComission(index, val)"
                                type="text"
                                class="mb-1"
                              />
                            </p>
                          </b-col>

                          <b-col cols="12" lg="2">
                            <p class="mb-0">
                              <label
                                for="invoice-data-sales-person"
                                class="text-nowrap mr-50"
                              >
                                Type Discount:
                              </label>
                            </p>

                            <v-select
                              v-model="item.type_discount"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="typeDiscount"
                              :reduce="(val) => val.value"
                              :clearable="false"
                              input-id="user-status"
                            />
                          </b-col>
                          <b-col cols="12" lg="1">
                            <label class="text-nowrap mr-50">Discount</label>
                            <b-form-input
                              v-model="item.discount"
                              type="number"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col cols="12" lg="1">
                            <label class="text-nowrap mr-50"></label>
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              size="md"
                              variant="primary"
                              @click="totalData"
                            >
                              Apply
                            </b-button>
                          </b-col>
                        </b-row>
                        <div
                          class="
                            d-flex
                            flex-column
                            justify-content-between
                            border-left
                            py-50
                            px-25
                          "
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  Add Item
                </b-button>
                <b-row>
                  <b-col cols="12" lg="4" class="mt-4">
                    <p class="mb-0">
                      <label
                        for="invoice-data-sales-person"
                        class="text-nowrap mr-50"
                      >
                        Payment Method:
                      </label>
                    </p>

                    <v-select
                      v-model="payment_method"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentMethod"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="user-status"
                      @input="totalData()"
                    />
                  </b-col>
                  <b-col cols="12" lg="4" class="mt-4">
                    <p class="mb-0">
                      <label
                        for="invoice-data-sales-person"
                        class="text-nowrap mr-50"
                      >
                        Selected point of sale:
                      </label>
                    </p>

                    <v-select
                      v-model="posId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="posAdmin"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :disabled="!buttonUpdate"
                    />
                  </b-col>
                  <b-col cols="12" lg="4" class="mt-4" v-show="refZelleShow">
                    <p class="mb-0">
                      <label
                        for="invoice-data-sales-person"
                        class="text-nowrap mr-50"
                      >
                        Ref Zelle:
                      </label>
                    </p>

                    <b-form-input v-model="refZelle" type="text" class="mb-2" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" lg="2" class="mt-2" v-show="tipAmountShow">
                    <p class="mb-0">
                      <label
                        for="invoice-data-sales-person"
                        class="text-nowrap mr-50"
                      >
                        Tip Amount:
                      </label>
                    </p>

                    <b-form-input
                      v-model="tipAmount"
                      type="number"
                      class="mb-2"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
           
              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Sales Persion -->
                  <b-col
                    v-if="invoiceData.total > 0"
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <label
                      for="invoice-data-sales-person"
                      class="text-nowrap mr-100"
                      v-show="invoiceData.tipsTotal > 0"
                    >
                      <h2>Tips:</h2>
                    </label>
                    <h3 class="ml-2" v-show="invoiceData.tipsTotal > 0">
                      {{ invoiceData.tipsTotal }}
                    </h3>
                    <label
                      for="invoice-data-sales-person"
                      class="text-nowrap ml-50"
                      v-show="invoiceData.tipsTotal > 0"
                    >
                      <h3 class="ml-2">Personalized</h3>
                    </label>
                    <b-form-checkbox
                      v-show="invoiceData.tipsTotal > 0"
                      v-model="invoiceData.tips"
                      checked="false"
                      name="check-button"
                      class="text-nowrap mr-50"
                      switch
                      inline
                    />
                    <!-- {{ invoiceData.tips }} -->
                  </b-col>
                  <b-col
                    v-else
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  ></b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Subtotal:</p>
                        <p class="invoice-total-amount">
                          $ {{ invoiceData.subTotal.toFixed(2) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Discount Total:</p>
                        <p class="invoice-total-amount">
                          $ {{ invoiceData.discount.toFixed(2) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Feed Payment:</p>
                        <p class="invoice-total-amount">
                          $ {{ invoiceData.feed.toFixed(2) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Tips:</p>
                        <p class="invoice-total-amount">
                          $ {{ invoiceData.tipsTotal.toFixed(2) }}
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Total:</p>
                        <p class="invoice-total-amount">
                          ${{ invoiceData.total.toFixed(2) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    v-if="invoiceData.tips"
                    cols="12"
                    md="12"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="1"
                    order-md="2"
                  >
                    <!-- TIPS CONFIG -->
                    <invoice-add-tips
                      :employeeData="invoiceData.items"
                      :tips="invoiceData.tipsTotal"
                      @dataTipsPersonalized="dataTipsPersonalized(1, $event)"
                    />
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing" />
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="12" xl="12" class="invoice-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="createInvoice()"
            :disabled="buttonSave"
          >
            Generate order
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="updateInvoice()"
            :disabled="buttonUpdate"
          >
            Update Order
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="saveTipsClover()"
            :disabled="buttonSaveTips"
          >
            Save Order
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="$router.go()"
          >
            Cancel
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import store from "@/store";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "../invoiceStoreModule";
import InvoiceSidebarAddNewCustomer from "../InvoiceSidebarAddNewCustomer.vue";
import InvoiceAddTips from "./invoiceAddTips.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarAddNewCustomer,
    InvoiceAddTips,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);

      this.totalData();
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });
    const toast = useToast();
    const validations = ref({
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    });
    const simpleRules = ref(null);
    localStorage.setItem("officeAdmin", "");

    const clients = ref([]);
    const officesAdmin = ref([]);
    const posAdmin = ref([]);
    const posId = ref(0);

    const parser = JSON.parse(localStorage.getItem("userData"));
    let idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;

    const payment_method = ref(0);

    const typeDiscount = [
      { label: "%", value: 1 },
      { label: "Fixed", value: 2 },
    ];

    const paymentMethod = [
      { label: "Cash", value: 1 },
      { label: "Debit", value: 2 },
      { label: "Credit", value: 3 },
      { label: "Zelle/Cash", value: 4 },
    ];

    const date = new Date();
    // Se crea el formulario por primera vez
    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: "",
      type_comission: 0,
      percentage_comission: 0,

      employee: "",
      employeeId: "",
      type: "",
      typeId: null,
      cloverId: "",
      variationsId: "",
      categoryType: null,
    };

    const invoiceData = ref({
      id: "",
      client: null,
      issuedDate: date.toISOString().split("T")[0],
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      salesPerson: "asas",
      note: "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!",
      total: 0,
      feed: 0,
      tips: false,
      tipsTotal: 0,
      subTotal: 0,
      discount: 0,
      employeeCommissionService: 0,
      officeId: idOffice,
      payment_method: payment_method,
      tipAmount: tipAmount,
      posId: posId,

      user: JSON.parse(localStorage.getItem("userData")),
    });

    const employeeOptions = ref([]);

    const relationItemsOptions = ref([]);

    const userData = ref(null);

    let employees = [];

    let product = [];

    let categoryTypeOptions = ref();

    const customer = [];

    const categoryOptions = ref();

    const categoryOptionsFilter = ref();

    const categoryRelationOptionsFilter = ref();

    const categoryRelationOptionsFilterService = ref();

    const EmployeeServiceOption = ref();

    const EmployeeServiceOptionFilter = ref();

    const buttonSave = ref(false);

    const buttonUpdate = ref(true);

    const buttonSaveTips = ref(true);

    const idOrder = ref(0);

    const officeFilter = [];

    const refZelle = ref();

    const tipAmount = ref(0);

    const refZelleShow = ref(false);

    const tipAmountShow = ref(false);

    const comissionCalculateBox = ref(false);

    const nroOrderPos = ref("");

    const nroOrder = ref("");
    let idTime = ref(0);

    if (idOffice !== null) {
      store
        .dispatch("app-invoice/fetchHelperSales", {
          idOffice,
          idRol,
        })
        .then((response) => {
          const {
            employee,
            products,
            customers,
            categoryType,
            categories,
            relationCategoryProducts,
            relationCategoryService,
            offices,
            pos,
          } = response.data.data;

          employee.map((data, item) => {
            employees.push({
              employee: `${data.first_name} ${data.last_name}`,
              value: data.id,
              id: item,
            });
          });

          products.map((data, item) => {
            product.push({
              id: item,
              value: data.id,
              product: data.name,
              cost: new Number(data.price).toFixed(2),
              type_comission: data.type_comission,
              percentage_comission: new Number(data.percentage_comission),
              cloverId: data.officeProducts[0].cloverId,
              variationsId: data.officeProducts[0].variationsId,
              qty: 1,
              description: data.description,
            });
          });
          customers.map((data, item) => {
            customer.push({
              id: data.id,
              customer: `${data.first_name} ${data.last_name}- ${data.mail} - ${data.phone_number}`,
              value: data.id,
              adress: data.adress,
              phone_number: data.phone_number,
              mail: data.mail,
            });
          });

          offices.map((data, item) => {
            officeFilter.push({
              id: data.id,
              name: data.name,
              address: data.adress,
            });
          });

          pos.map((data, item) => {
            posAdmin.value.push({
              id: data.id,
              value: data.id,
              label: data.name,
              status: data.status,
            });
          });
          if (posAdmin.value.length) {
            posId.value = posAdmin.value[0].id;
          }

          clients.value = customer;

          officesAdmin.value = officeFilter;

          categoryTypeOptions.value = categoryType;

          employeeOptions.value = employees;

          EmployeeServiceOptionFilter.value = employees;

          categoryOptionsFilter.value = categories;

          categoryRelationOptionsFilter.value = relationCategoryProducts;

          categoryRelationOptionsFilterService.value = relationCategoryService;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    } else {
      store
        .dispatch("app-invoice/fetchHelperSales", {
          idOffice,
          idRol,
        })
        .then((response) => {
          const {
            employee,
            products,
            customers,
            categoryType,
            categories,
            relationCategoryProducts,
            relationCategoryService,
            offices,
          } = response.data.data;

          customers.map((data, item) => {
            customer.push({
              id: data.id,
              customer: `${data.first_name} ${data.last_name}- ${data.mail} - ${data.phone_number}`,
              value: data.id,
              adress: data.adress,
              phone_number: data.phone_number,
              mail: data.mail,
            });
          });

          offices.map((data, item) => {
            officeFilter.push({
              id: data.id,
              name: data.name,
              address: data.adress,
            });
          });

          clients.value = customer;

          officesAdmin.value = officeFilter;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    }

    const itemsOptions = [];

    const updateItemForm = (index, val) => {
      const {
        cost,
        qty,
        name,
        description,
        type_comission,
        percentage_comission,
        type_discount,
        employee,
        type,
        value,
        cloverId,
        variationsId,
      } = val;

      invoiceData.value.items[index].cost = cost;
      invoiceData.value.items[index].qty = qty;
      invoiceData.value.items[index].name = name;
      invoiceData.value.items[index].description = description;
      invoiceData.value.items[index].type_comission = type_comission;
      type_discount;
      invoiceData.value.items[index].percentage_comission =
        percentage_comission;
      invoiceData.value.items[index].type_discount = type_discount;
      invoiceData.value.items[index].employee = employee;
      invoiceData.value.items[index].typeId = type;
      invoiceData.value.items[index].item = value;
      invoiceData.value.items[index].cloverId = cloverId;
      invoiceData.value.items[index].variationsId = variationsId;

      const total = invoiceData.value.items.map((element, index) => {
        return element.cost * element.qty;
      });

      invoiceData.value.total = total.reduce(function (acumulador, item) {
        return acumulador + item;
      });
      invoiceData.value.subTotal = invoiceData.value.total;

      const itemEmployeeService = EmployeeServiceOption.value.filter((data) => {
        if (data.id === value) {
          return data.employeeService;
        }
      });

      totalData();

      if (itemEmployeeService.length > 0) {
        const employees = [];
        const parser = JSON.parse(localStorage.getItem("userData"));
        let idOffice = parser.dataUser.officeEmployee_officeId;
        idOffice !== null
          ? idOffice
          : (idOffice = localStorage.getItem("officeAdmin"));

        itemEmployeeService.map((data, item) => {
          data.employeeService.map((data1, index) => {
            const { first_name, last_name, id, status, officeEmployee } =
              data1.employee;

            officeEmployee.map((data3, index3) => {
              if (status === true && data3.officeId == idOffice) {
                employees.push({
                  employee: `${first_name} ${last_name}`,
                  value: id,
                  id: index,
                  percentage_comission: data1.percentage_comission,
                });
              }
            });
          });
        });
        employeeOptions.value = employees;
      } else {
        employeeOptions.value = EmployeeServiceOptionFilter.value;
      }
    };

    const updateComission = (index, val) => {
      invoiceData.value.items[index].percentage_comission = val;
      invoiceData.value.items[index].type_comission = 2;
    };

    const totalDataItemInputCost = (index, val) => {
      const { value, employee, percentage_comission } = val;

      invoiceData.value.items[index].employee = employee;
      invoiceData.value.items[index].employeeId = value;

      percentage_comission !== undefined
        ? (invoiceData.value.items[index].percentage_comission =
            percentage_comission)
        : "";

      const total = invoiceData.value.items.map((element, index) => {
        return element.cost * element.qty;
      });

      invoiceData.value.total = total.reduce(function (acumulador, item) {
        return acumulador + item;
      });

      invoiceData.value.subTotal = invoiceData.value.total;
      totalData();
    };

    const totalData = () => {
      if (invoiceData.value.items.length > 0) {
        const total = invoiceData.value.items.map((element, index) => {
          if (element.type_discount == 1) {
            let descuento = (element.cost * Number(element.discount)) / 100;

            return (element.cost - descuento) * element.qty;
          }
          if (element.type_discount == 2) {
            return element.cost * element.qty - Number(element.discount);
          }
          return element.cost * element.qty;
        });

        const subTotal = invoiceData.value.items.map((element, index) => {
          return element.cost * element.qty;
        });
        const discount = invoiceData.value.items.map((element, index) => {
          if (element.type_discount == 1) {
            let descuento = (element.cost * Number(element.discount)) / 100;

            return descuento * element.qty;
          }
          if (element.type_discount == 2) {
            return Number(element.discount);
          }
          return 0;
        });

        invoiceData.value.total = total.reduce(function (acumulador, item) {
          return acumulador + item;
        });
        invoiceData.value.subTotal = subTotal.reduce(function (
          acumulador,
          item
        ) {
          return acumulador + item;
        });
        invoiceData.value.discount = discount.reduce(function (
          acumulador,
          item
        ) {
          return acumulador + item;
        });

        if (payment_method.value == 3) {
          invoiceData.value.total = invoiceData.value.total * 1.0399;

          invoiceData.value.feed =
            (invoiceData.value.subTotal - invoiceData.value.discount) * 0.0399;
          refZelleShow.value = false;
          tipAmountShow.value = false;
          refZelle.value = "";
        } else {
          invoiceData.value.total = invoiceData.value.total + 0;
          invoiceData.value.feed = 0;
          refZelleShow.value = false;
          tipAmountShow.value = false;
          refZelle.value = "";
          if (payment_method.value == 4) {
            refZelleShow.value = true;
            tipAmountShow.value = true;
          }
        }
        if (invoiceData.value.total == 0 || payment_method.value == 3) {
          tipAmountShow.value = true;
        }
      } else {
        invoiceData.value.total = 0;
        invoiceData.value.discount = 0;
        invoiceData.value.subTotal = 0;
      }
    };

    const addTipsTotalInvoice = () => {
      const total = invoiceData.value.items.map((element, index) => {
        return element.cost * element.qty;
      });

      const acumulador = total.reduce(function (acumulador, item) {
        return acumulador + item;
      });
      parseInt(invoiceData.value.tipsTotal) > 0
        ? (invoiceData.value.total =
            acumulador + parseInt(invoiceData.value.tipsTotal))
        : (invoiceData.value.total = acumulador);
    };

    const arrayEmployeeCommisionPerzonalized = [];

    const createInvoice = async () => {
      //buttonSave.value = true;

      if (!invoiceData.value.client) {
        return toast({
          component: ToastificationContent,
          props: {
            title: `Client Empty`,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }

      if (payment_method.value == 4 && !refZelle.value) {
        return toast({
          component: ToastificationContent,
          props: {
            title: `Ref Zelle Empty`,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }

      let matches = ref("");
      let element = invoiceData.value.items.length;
      let i = 0;
      invoiceData.value.tipAmount = tipAmount.value;

      invoiceData.value.items.forEach((e) => {
        if (!e.item) {
          return toast({
            component: ToastificationContent,
            props: {
              title: `Items Empty`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else if (!e.employeeId) {
          return toast({
            component: ToastificationContent,
            props: {
              title: `Employee Empty`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else {
          i++;
        }

        if (i == element) {
          matches = "verify";
        }
      });
      if (matches == "verify") {
        store
          .dispatch("app-invoice/addInvoice", invoiceData)
          .then((response) => {
            console.log("order-->", response.data.orderGenerate.idOrderClover);
            if (response.data.ok == false) {
              toast({
                component: ToastificationContent,
                props: {
                  title: response.data.msg,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
            nroOrderPos.value = response.data.orderGenerate.idOrderClover;
            nroOrder.value = response.data.orderGenerate.nro_order;
            buttonSave.value = true;
            buttonUpdate.value = false;

            const parser = JSON.parse(localStorage.getItem("userData"));
            let idOffice = parser.dataUser.officeEmployee_officeId;
            idOffice !== null
              ? idOffice
              : (idOffice = localStorage.getItem("officeAdmin"));
            const idRol = parser.userData.rolId;
            const data = {
              idOrderClover: response.data.orderGenerate.idOrderClover,
              nroOrder: response.data.orderGenerate.nro_order,
              orderTotal: response.data.orderGenerate.total_order,
              idOffice,
              paymentMethod: payment_method.value,
              refZelle: refZelle.value,
              tipAmount: tipAmount.value,
            };
            //Preguntamos satus
            verifyPayTime(data);
            toast({
              component: ToastificationContent,
              props: {
                title: `Payment in process`,
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            if (error.response && error.response.data.error) {
              return toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.error,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            } else {
              return toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al conectar con el servidor',
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const updateInvoice = () => {
      if (nroOrderPos.value && nroOrder.value) {
        invoiceData.value.id = nroOrder.value;
        invoiceData.value.nroOrderPos = nroOrderPos.value;
      }
      store
        .dispatch("app-invoice/editInvoice", invoiceData)
        .then((response) => {
          console.log("order-->", response.data);
          if (response.data.ok == false) {
            return toast({
              component: ToastificationContent,
              props: {
                title: response.data.msg,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }

          const data = {
            idOrderClover: invoiceData.value.nroOrderPos,
            nroOrder: invoiceData.value.id,
            orderTotal: invoiceData.value.total,
            idOffice,
            paymentMethod: payment_method.value,
            refZelle: refZelle.value,
            tipAmount: tipAmount.value,
            posId: posId.value,
          };
          //Preguntamos satus
          clearInterval(idTime);
          verifyPayTime(data);

          toast({
            component: ToastificationContent,
            props: {
              title: `Order Update`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        });
    };

    const verifyPayTime = async (data) => {
      const very = setInterval(() => {
        store
          .dispatch("app-invoice/statusPayClover", data)
          .then((response) => {
            if (response.data.pay) {
              buttonUpdate.value = true;
              if (!response.data.totalTips) {
                router.push({ name: "apps-invoice-list" });
              }
              proceessTips(response.data, data);
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching invoices' list",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }, 3000);
      console.log("que tiene vey, ", very);
      idTime = very;
      const proceessTips = (data, dataOrder) => {
        invoiceData.value.tipsTotal = 0;
        if (data.totalTips) {
          invoiceData.value.tipsTotal = data.totalTips;
          invoiceData.value.total = data.totalOrder / 100;
        }
        console.log("Datoooossss -> ", dataOrder.nroOrder);
        idOrder.value = dataOrder.nroOrder;

        let totalServices = 0;
        let existServices = true;
        for (const item of invoiceData.value.items) {
          if (item.type && item.type.name == 'Services') {
            totalServices++;
          }
        }

        if (!totalServices) {
          totalServices = invoiceData.value.items.length;
          existServices = false;
        }

        const tipPerService = invoiceData.value.tipsTotal / totalServices;
        invoiceData.value.items.forEach((item) => {
          if (existServices) {
            if (item.type && item.type.name == 'Services') {
              item.tip = tipPerService;
              item.typeTip = 'Services';
            }
          } else {
            item.tip = tipPerService;
            item.typeTip = 'Services';
          }
        });

        clearInterval(very);
        buttonSaveTips.value = false;

        toast({
          component: ToastificationContent,
          props: {
            title: `Successful Payment`,
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      };
    };

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,

      employeeOptions,
      relationItemsOptions,
      categoryTypeOptions,
      totalData,
      totalDataItemInputCost,
      buttonSave,
      buttonUpdate,
      buttonSaveTips,
      idOrder,
      idOffice,
      validations,
      simpleRules,
      posAdmin,
      posId,

      categoryOptions,
      typeDiscount,
      paymentMethod,
      payment_method,
      officesAdmin,
      refZelle,
      tipAmount,
      refZelleShow,
      tipAmountShow,
      updateComission,
      createInvoice,
      updateInvoice,
      typeCategory: (index, val) => {
        categoryOptions.value = [];
        relationItemsOptions.value = [];
        categoryOptions.value = categoryOptionsFilter.value.filter(
          (data) => data.typeId === val.id
        );
      },
      CategoryItem: (index, val) => {
        const items = [];
        relationItemsOptions.value = [];

        categoryRelationOptionsFilter.value.filter((data, item) => {
          if (data.categoryId === val.id) {
            items.push({
              id: item,
              value: data.product.id,
              name: data.product.name,
              cost: data.product.price,
              type_comission: data.product.type_comission,
              percentage_comission: data.product.percentage_comission,
              qty: 1,
              description: data.product.description,
              officeId: 3,
              cloverId: data.product.officeProducts[0].cloverId,
              variationsId: data.product.officeProducts[0].variationsId,
            });
          }
        });

        const EmployeeService = [];

        items.length === 0
          ? categoryRelationOptionsFilterService.value.filter((data, item) => {
              if (data.categoryId === val.id) {
                EmployeeService.push(data.service);
                items.push({
                  id: item,
                  value: data.service.id,
                  name: data.service.name,
                  cost: data.service.price,
                  qty: 1,
                  cloverId: data.service.officeService[0].cloverId,
                  variationsId: data.service.officeService[0].variationsId,
                  description: "",
                });
              }
            })
          : items;

        EmployeeServiceOption.value = EmployeeService;
        relationItemsOptions.value = items;
      },

      addTipsTotalInvoice,
      dataTipsPersonalized: (data, value) => {
        const { index, item } = value;

        if (arrayEmployeeCommisionPerzonalized[index]) {
          let dataEmployee = arrayEmployeeCommisionPerzonalized.find(
            (data) => data.id === index
          );

          dataEmployee.commission = item.commission;
          dataEmployee.total = item.total;
        } else {
          arrayEmployeeCommisionPerzonalized.push({
            id: index,
            empoyeeId: item.id,
            commission: item.commission,
            total: item.total,
          });
        }

        invoiceData.value.employeeCommissionService =
          arrayEmployeeCommisionPerzonalized;
      },
      saveTipsClover: () => {
        console.log(
          "VEamos Que tenemos en invoice",
          invoiceData,
          " id order ->",
          idOrder
        );

        const employees = [];
        for (const item of invoiceData.value.items) {
          if (item.typeTip == 'Services') {
            employees.push({
              id: item.employeeId,
              employeeId: item.employeeId,
              name: item.employee,
              commission: item.tip,
              total: item.tip,
              progress: item.tip,
            });
          }
        }

        if (!employees.length) {
          for (const item of invoiceData.value.items) {
            employees.push({
              id: item.employeeId,
              employeeId: item.employeeId,
              name: item.employee,
              commission: item.tip,
              total: item.tip,
              progress: item.tip,
            });
          }
        }

        const tips = {
          idOrder: idOrder.value,
          total: invoiceData.value.tipsTotal,
          multi: invoiceData.value.tips,
          listMulti: invoiceData.value.employeeCommissionService,
          employes: employees,
          cntEmployes: employees.length,
        };

        console.log("Como quedo para tips", tips);
        store
          .dispatch("app-invoice/addTips", tips)
          .then((response) => {
            if (response.data.tips == "ok") {
              toast({
                component: ToastificationContent,
                props: {
                  title: `Successful Tips`,
                  icon: "AlertTriangleIcon",
                  variant: "success",
                },
              });

              router.push({ name: "apps-invoice-list" });
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching invoices' list",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      },

      filterAdminOffice: (id) => {
        invoiceData.value.officeId = id;
        localStorage.setItem("officeAdmin", id);

        employees = [];
        product = [];

        store
          .dispatch("app-invoice/fetchHelperSales", {
            idOffice: id,
            idRol,
          })
          .then((response) => {
            const {
              employee,
              products,
              categoryType,
              categories,
              relationCategoryProducts,
              relationCategoryService,
            } = response.data.data;

            employee.map((data, item) => {
              employees.push({
                employee: `${data.first_name} ${data.last_name}`,
                value: data.id,
                id: item,
              });
            });

            products.map((data, item) => {
              product.push({
                id: item,
                value: data.id,
                product: data.name,
                cost: new Number(data.price).toFixed(2),
                type_comission: data.type_comission,
                percentage_comission: new Number(data.percentage_comission),
                cloverId: data.officeProducts[0].cloverId,
                variationsId: data.officeProducts[0].variationsId,
                qty: 1,
                description: data.description,
              });
            });

            categoryTypeOptions.value = categoryType;

            employeeOptions.value = employees;

            EmployeeServiceOptionFilter.value = employees;

            categoryOptionsFilter.value = categories;

            categoryRelationOptionsFilter.value = relationCategoryProducts;

            categoryRelationOptionsFilterService.value =
              relationCategoryService;
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 404) {
              userData.value = undefined;
            }
          });
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>